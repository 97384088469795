/* eslint-disable require-jsdoc */
import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import Header from "../../../../../components/header/index.js";
import Sidebar1 from "../../../../../components/sidebar/sidebar.js";
import Button from "@material-ui/core/Button";
import { withSnackbar } from "notistack";
import { TrainRounded } from "@material-ui/icons";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Loader from "../../../../../components/loader/index.js";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import axios from "axios";
import * as moment from "moment";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  GetDataByDefaultInputs,
  GetDataByPaymentDatesDeal,
  GetDataByUpdateAsset,
  GetDataByUpdateIssuer,
} from "../../../../../servies/services.js";
import LinearLoader from "../../../../../components/loader/LinearLoader.js";
import PerformanceDropDowns from "./PerformanceDropDowns.js";
import DataDisplaySummary from "./DataDisplaySummary.js";
import { MultiSelect } from "react-multi-select-component";
import Select, { components } from "react-select";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import ChatAssistant from "./ChatAssistant.js";
import IDA from "../../../../../images/IDA.svg";
import BETA from "../../../../../images/BETA.svg";

// Custom dropdown indicator component
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? <FaChevronUp /> : <FaChevronDown />}
    </components.DropdownIndicator>
  );
};

const customValueRenderer = (selected) => {
  if (selected.length === 0) {
    return "Select one or more";
  } else {
    return selected.map(({ label }) => label).join(", ");
  }
};
class DealAnalyticsPerformance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRow: null,
      token: sessionStorage.getItem("token"),
      loading: false,
      getLoansLoader: false,
      isDataLoaded: false,
      openPopup: false,
      startDate: new Date("2022-01-01"), // Setting startDate to May 1, 2024
      endDate: new Date("2024-07-25"),
      searchText: "",
      tableData: [],
      tableData1: [],
      tableData2: [],
      tableData3: [],
      assetClassOptions: [],
      selectedAssetClass: [], // Selected option for react-select
      IssuerGroup: [],
      issuerOptions: [], // New state for Issuer options
      selectedIssuer: [], // New state for selected Issuers
      dealsOptions: [], // New state for Deals options
      selectedDeals: [],
      selectedDates: [],
      DealName: sessionStorage.getItem("dealname"),
      NextPaymentDate: sessionStorage.getItem("nextpaymentdate"),
      LastPaymentDate: sessionStorage.getItem("lastpaymentdate"),
      Email: sessionStorage.getItem("emailid"),
      Password: sessionStorage.getItem("Pass"),
      TableName: "General",
      peer: sessionStorage.getItem("peer"),
      peers: JSON.parse(sessionStorage.getItem("peers")),
      rowsSelected: null,
      investorTab1: false,
      investorTab2: false,
      investorTab3: true,
      screenloader: false,
      pooldetails: {},
      bdbUrl: "",
      formData1: {
        "First Payment Date": "",
      },
    };
    // Bind the handleSelectChange function
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleDealsChange = this.handleDealsChange.bind(this);
  }
  blockInvalidChar = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  handleClickSummary = () => {
    this.setState({
      investorTab1: true,
      investorTab2: false,
      investorTab3: false,
    });
    window.location.assign("/investor/summaryAnalytics");
  };

  handleClickStrats = () => {
    this.setState({
      investorTab1: false,
      investorTab2: true,
      investorTab3: false,
    });
    window.location.assign("/investor/stratsAnalytics");
  };

  handleClickPerformance = () => {
    this.setState({
      investorTab1: false,
      investorTab2: false,
      investorTab3: true,
    });
    window.location.assign("/investor/performanceAnalytics");
  };

  handleSelectChange(selectedOption) {
    // Ensure selectedAssetClass is an array if it's undefined
    const selectedAssetClass = this.state.selectedAssetClass || [];

    console.log("selectedAssetClass", selectedAssetClass, selectedOption);

    this.setState({
      selectedAssetClass: selectedOption ? [selectedOption] : [], // If selectedOption exists, update; otherwise, set to []
      tranChange: !!selectedOption, // Set tranChange to true if selectedOption exists
    });
  }

  handleIssuerChange = (selectedOptions) => {
    this.setState({ selectedIssuer: selectedOptions, screenloader: true });
  };

  handleDealsChange = (selectedOptions) => {
    this.setState({ selectedDeals: [selectedOptions], screenloader: true });
  };

  // handleDatesChange = (selectedOptions) => {
  //   console.log("selectedOptions", selectedOptions);

  //   const startDate =
  //     this.state.dateOptions.length > 0 ? this.state.dateOptions[0].value : "";

  //   // If selectedOptions is null (cleared), set startDate and selectedDates accordingly
  //   if (!selectedOptions) {
  //     this.setState({
  //       selectedDates: [],
  //       startDate: startDate,
  //       // endDate: "",
  //     });
  //     return;
  //   }

  //   const endDate =
  //     this.state.dateOptions.length > 0
  //       ? this.state.dateOptions[this.state.dateOptions.length - 1].value
  //       : "";

  //   this.setState({
  //     selectedDates: [selectedOptions],
  //     startDate: selectedOptions.value,
  //     endDate: endDate,
  //   });
  // };

  GetDataByDefaultInputs = async () => {
    var data = {};
    data.userName = sessionStorage.getItem("user_name");

    console.log("datata", data);
    this.setState({
      screenloader: true,
      tableData: [],
      loading: true,
    });

    try {
      const APIResponse = await GetDataByDefaultInputs(data);

      if (APIResponse.status === 200) {
        const result = APIResponse.data.result[0]; // Access the first object in the result array

        const assetClassArray = result?.assetTypeList || [];
        const assetClassOptions = assetClassArray.map((option) => ({
          value: option,
          label: option,
        }));

        const issuerArray = result?.issuerGroupList || [];
        const issuerOptions = issuerArray.map((option) => ({
          value: option,
          label: option,
        }));

        const dealsArray = result?.dealTypeList || [];
        const dealsOptions = dealsArray.map((deal) => ({
          value: deal,
          label: deal,
        }));

        const firstDealSelected =
          dealsOptions.length > 0 ? [dealsOptions[0]] : null;

        this.setState(
          {
            getLoansLoader: false,
            AssetClass: assetClassArray,
            assetClassOptions: assetClassOptions,
            IssuerGroup: issuerArray,
            issuerOptions: issuerOptions,
            Deals: dealsArray,
            dealsOptions: dealsOptions,
            selectedAssetClass: assetClassOptions,
            selectedIssuer: issuerOptions,
            selectedDeals: firstDealSelected,
            loading: false,
          },
          async () => {
            // Call the cascading methods one after another
            await this.GetDataByUpdateAsset();
            await this.GetDataByUpdateIssuer();
            // await this.GetDataByPaymentDatesDeal();
          }
        );
      } else {
        throw new Error("API error");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      this.setState({ getLoansLoader: false, loading: false });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  GetDataByUpdateAsset = async () => {
    var data = {};
    const userName = sessionStorage.getItem("user_name");
    data.UserName = userName;
    // Ensure selectedAssetClass is an array before using map
    const selectedAssetClass = Array.isArray(this.state.selectedAssetClass)
      ? this.state.selectedAssetClass
      : [];

    data.asset_class = selectedAssetClass.map((item) => item.value);
    // console.log("datata", data);
    this.setState({
      screenloader: true,
      getLoansLoader: true,
      tableData: [],
      loading: true,
    });
    const APIResponse = await GetDataByUpdateAsset(data);
    // console.log("APIResponse", APIResponse);
    if (APIResponse.status === 200) {
      const issuerArray = APIResponse.data.result.issuer_group;
      const issuerOptions = issuerArray.map((option) => ({
        value: option,
        label: option,
      }));

      const shouldClearIssuer = selectedAssetClass.length === 0;

      this.setState(
        {
          getLoansLoader: false,
          // AssetClass: assetClassArray,
          // assetClassOptions: assetClassOptions,
          IssuerGroup: issuerArray,
          issuerOptions: issuerOptions, // Set issuer options
          selectedIssuer: shouldClearIssuer
            ? []
            : this.state.selectedIssuer.length > 0
            ? this.state.selectedIssuer
            : issuerOptions,
          // Deals: dealsArray,
          // dealsOptions: dealsOptions,
          // selectedAssetClass: allAssetClassesSelected, // Set all asset classes as selected
          // selectedIssuer: allIssuersSelected, // Set all issuers as selected
          // selectedDeals: allDealsSelected, // Set all deals as selected
          loading: false,
        },
        () =>
          console.log(
            "selectedIssuer",
            shouldClearIssuer,
            this.state.selectedIssuer,
            issuerArray
          )
      );
      // Call GetDataByUpdateIssuer and GetDataByTableChartData when tranChange is true
      if (this.state.tranChange) {
        // await this.GetDataByUpdateIssuer();
        this.setState({ tranChange: false });
      }
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };

  GetDataByUpdateIssuer = async () => {
    var data = {};
    const userName = sessionStorage.getItem("user_name");
    data.UserName = userName;
    // Ensure selectedAssetClass is an array before using map
    const selectedIssuer = Array.isArray(this.state.selectedIssuer)
      ? this.state.selectedIssuer
      : [];

    data.issuer_group = selectedIssuer.map((item) => item.value);
    console.log("datata", data);
    this.setState({ getLoansLoader: true, tableData: [], loading: true });
    const APIResponse = await GetDataByUpdateIssuer(data);
    console.log("APIResponse", APIResponse);
    if (APIResponse.status === 200) {
      // Assuming deals data is in APIResponse.data.result.deals
      const dealsArray = APIResponse.data.result.deal_type;
      const dealsOptions = dealsArray.map((deal) => {
        // Extract the key and value from each deal object
        const [key, value] = Object.entries(deal)[0];
        return { value: key, label: value };
      });

      // Select the first deal by default
      const firstDealSelected =
        dealsOptions.length > 0 ? [dealsOptions[0]] : null;

      this.setState({
        getLoansLoader: false,
        // screenloader: false,
        // AssetClass: assetClassArray,
        // assetClassOptions: assetClassOptions,
        // IssuerGroup: issuerArray,
        // issuerOptions: issuerOptions, // Set issuer options
        Deals: dealsArray,
        dealsOptions: dealsOptions,
        // selectedAssetClass: allAssetClassesSelected, // Set all asset classes as selected
        // selectedIssuer: allIssuersSelected, // Set all issuers as selected
        selectedDeals: firstDealSelected, // Set all deals as selected
        loading: false,
        // screenloader: false,
      });
    } else {
      this.setState({ getLoansLoader: false, loading: false });
      const message = "Something went wrong";
      this.props.enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };
  GetDataByPaymentDatesDeal = async () => {
    const { selectedDeals, selectedDates, Deals, startDate, endDate } =
      this.state;

    var data = {};

    // Check if selectedDeals is an array and has at least one item
    if (Array.isArray(selectedDeals) && selectedDeals.length > 0) {
      data.deal_id = selectedDeals[0].value;
    } else {
      console.error("selectedDeals is either null, undefined, or empty");
      // Handle the error case or assign a default value if necessary
      data.deal_id = ""; // Or assign some default value
    }

    this.setState({
      screenloader: true,
      loading: true,
      selectedDates: [{ value: "All", label: "All" }],
    });

    try {
      const APIResponse = await GetDataByPaymentDatesDeal(data);
      console.log("APIResponse", APIResponse);

      if (APIResponse.status === 200) {
        const dateOptions =
          Array.isArray(APIResponse.data.payment_dates) &&
          APIResponse.data.payment_dates.length > 0
            ? APIResponse.data.payment_dates.map((date) => {
                // Assuming `date` is a string, directly map it to the object format
                return { value: date, label: date };
              })
            : [];

        console.log("dateOptions", dateOptions);

        const startDate =
          dateOptions.length > 0
            ? dateOptions[dateOptions.length - 1].value
            : "";
        const endDate = dateOptions.length > 0 ? dateOptions[0].value : "";

        this.setState({
          getLoansLoader: false,
          screenloader: false,
          startDate: startDate,
          endDate: endDate,
          dateOptions: dateOptions,
        });
      } else {
        this.setState({ getLoansLoader: false, loading: false });
        const message = "Something went wrong";
        this.props.enqueueSnackbar(message, {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      this.setState({ getLoansLoader: false, loading: false });
      this.props.enqueueSnackbar("An error occurred while fetching data", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  };
  async componentDidMount() {
    var component = window.location.pathname;
    sessionStorage.setItem("component", component);

    // Fetch data from API
    await this.GetDataByDefaultInputs();

    // Mark data as loaded after the initial API call
    this.setState({ isDataLoaded: true, hasLoaded: true }); // Add hasLoaded flag
  }

  async componentDidUpdate(prevProps, prevState) {
    // Skip if initial data is not loaded
    if (!this.state.hasLoaded) return;

    // Helper function to map values safely
    const mapValues = (items) =>
      Array.isArray(items)
        ? items
            .filter((item) => item != null && typeof item === "object")
            .map((item) => item.value)
        : [];

    // Safely map values for each state variable
    const prevAssetValues = mapValues(prevState.selectedAssetClass);
    const currentAssetValues = mapValues(this.state.selectedAssetClass);

    const prevIssuerValues = mapValues(prevState.selectedIssuer);
    const currentIssuerValues = mapValues(this.state.selectedIssuer);

    const prevDealsValues = mapValues(prevState.selectedDeals);
    const currentDealsValues = mapValues(this.state.selectedDeals);

    console.log("prevIssuerValues", prevIssuerValues, currentIssuerValues);
    console.log("prevDealsValues", prevDealsValues, currentDealsValues);

    // Check if the selectedAssetClass values have changed
    if (
      JSON.stringify(prevAssetValues) !== JSON.stringify(currentAssetValues)
    ) {
      await this.GetDataByUpdateAsset();
    }

    // Check if the selectedIssuer values have changed
    if (
      JSON.stringify(prevIssuerValues) !== JSON.stringify(currentIssuerValues)
    ) {
      await this.GetDataByUpdateIssuer();
    }

    // Only call GetDataByPaymentDatesDeal if deals have changed
    if (
      JSON.stringify(prevDealsValues) !== JSON.stringify(currentDealsValues) &&
      this.state.screenloader
    ) {
      await this.GetDataByPaymentDatesDeal();
    }
  }

  render() {
    const customStyles = {
      container: (provided) => ({
        ...provided,
        width: "200px", // Set the fixed width of the input box
      }),
      control: (provided) => ({
        ...provided,
        border: "1px solid black",
        borderRadius: "8px",
        minHeight: "38px",
        boxShadow: "none",
      }),
      placeholder: (provided) => ({
        ...provided,
        color: "#adaeaa",
      }),
      singleValue: (provided) => ({
        ...provided,
        color: "#000000",
      }),
      menu: (provided) => ({
        ...provided,
        width: "auto", // Dynamically set the width of the dropdown
        minWidth: "200px", // Ensure it doesn’t become smaller than the input
        zIndex: 9999, // Ensure the dropdown appears above other elements
      }),
      option: (provided) => ({
        ...provided,
        whiteSpace: "nowrap", // Prevent text from wrapping in the dropdown
      }),
      indicatorSeparator: () => ({
        display: "none", // Remove the vertical separator line
      }),
    };

    const { startDate, endDate } = this.state;

    const sendStartDate = moment(startDate, "DD-MM-YYYY").format("YYYY-MM-DD");
    const sendEndDate = moment(endDate, "DD-MM-YYYY").format("YYYY-MM-DD");

    return (
      <React.Fragment>
        <div className="">
          <Sidebar1 activeComponent={"Deal Analytics"} />
          <div className="content-bdb">
            <div className="header">{/* <Header></Header> */}</div>
            {this.state.screenloader == true ? (
              <LinearLoader></LinearLoader>
            ) : (
              <>
                <div className="page-contentofpool1">
                  <div className="row1">
                    <div
                      className="col-5 col-sm-6 col-md-3 d-flex hellocard"
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "90rem",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h3 className="headerdashboard-analytics">DEAL</h3>
                      </div>
                    </div>
                  </div>

                  <div className="FixTails">
                    <div className="dropdown-row">
                      <div className="dropdown-container">
                        <label className="dropdown-label">Asset Class:</label>
                        <div className="dropdown-content-topBar">
                          <Select
                            options={this.state.assetClassOptions}
                            value={this.state.selectedAssetClass}
                            onChange={this.handleSelectChange}
                            isClearable
                            placeholder="Select any one"
                            styles={customStyles}
                            components={{ DropdownIndicator }}
                          />
                        </div>
                      </div>

                      <div className="dropdown-container">
                        <label className="dropdown-label">Issuer Group:</label>
                        <div className="dropdown-content-topBar">
                          <MultiSelect
                            options={this.state.issuerOptions}
                            value={this.state.selectedIssuer}
                            onChange={this.handleIssuerChange}
                            valueRenderer={customValueRenderer}
                            labelledBy="Select"
                            className="custom-multiselect multiselect-width"
                          />
                        </div>
                      </div>

                      <div className="dropdown-container">
                        <label className="dropdown-label">Deals:</label>
                        <div className="dropdown-content-topBar">
                          <Select
                            options={this.state.dealsOptions}
                            value={this.state.selectedDeals}
                            onChange={this.handleDealsChange}
                            isClearable
                            placeholder="Select deals"
                            styles={customStyles}
                            components={{ DropdownIndicator }}
                          />
                        </div>
                      </div>

                      {/* <div className="dropdown-container">
                        <label className="dropdown-label">Date:</label>
                        <div className="dropdown-content-topBar">
                          <Select
                            options={this.state.dateOptions}
                            value={this.state.selectedDates}
                            onChange={this.handleDatesChange}
                            isClearable
                            placeholder="All"
                            styles={customStyles}
                            components={{ DropdownIndicator }}
                          />
                        </div>
                      </div> */}
                    </div>

                    <DataDisplaySummary />

                    <div className="SummaryStds">
                      <div className="col-7 col-sm-6 col-md-7 hellocard">
                        <button
                          type="button"
                          onClick={() => this.handleClickSummary()}
                          className={
                            this.state.investorTab1 == true
                              ? "issuerDashboard-table-top-button-insights-active-analytics"
                              : "issuerDashboard-table-top-button-insights-analytics"
                          }
                        >
                          Summary
                        </button>
                        <button
                          type="button"
                          onClick={() => this.handleClickStrats()}
                          className={
                            this.state.investorTab2 == true
                              ? "issuerDashboard-table-top-button-insights-active-analytics"
                              : "issuerDashboard-table-top-button-insights-analytics"
                          }
                        >
                          Strats
                        </button>
                        <button
                          type="button"
                          onClick={() => this.handleClickPerformance()}
                          className={
                            this.state.investorTab3 == true
                              ? "issuerDashboard-table-top-button-insights-active-analytics"
                              : "issuerDashboard-table-top-button-insights-analytics"
                          }
                        >
                          Performance
                        </button>
                      </div>

                      <div className="IDA_Virtual">
                        <div className="IDA_Icons">
                          <img src={IDA} alt="VirtualAsst" />
                          <img src={BETA} alt="VirtualAsst" className="beta" />
                        </div>{" "}
                        <ChatAssistant />
                      </div>
                    </div>
                  </div>

                  <div className="scrollable-content-dash workbench-table-container">
                    {this.state.isDataLoaded && (
                      <PerformanceDropDowns
                        startDate={sendStartDate}
                        endDate={sendEndDate}
                        selectedDates={this.state.selectedDates}
                        selectedDeals={this.state.selectedDeals}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSnackbar(DealAnalyticsPerformance);

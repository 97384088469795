import React, { useState, useEffect } from "react";
import { Table, Typography } from "antd";
import { v4 as uuidv4 } from "uuid";
import { ViewStratsTab as fetchViewStratsTab } from "../../../../../servies/services.js";

const { Title } = Typography;

const Strats = ({
  selectedPrimary,
  selectedSecondary,
  selectedTertiary,
  selectedAsOf,
  selectedDeals,
}) => {
  const [tableData, setTableData] = useState({});
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [expandedRowKeys, setExpandedRowKeys] = useState({});
  const pageSize = 5;

  const generateColumns = (data) => {
    // Dynamically generate columns based on data keys
    const baseColumns = [
      {
        title: "",
        dataIndex: "primVar",
        key: "primVar",
        fixed: "left",
        onHeaderCell: () => ({
          style: {
            textAlign: "center", // Custom header alignment for primary column
          },
        }),
        render: (text, record) => {
          if (record.primVar) return <strong>{record.primVar}</strong>;
          if (record.secVar) return <strong>{record.secVar}</strong>;
          if (record.thrVar) return <strong>{record.thrVar}</strong>;
          return null;
        },
      },
    ];

    // Extract all keys from the first entry of the data to build columns
    const dataKeys = Object.keys(data?.[0] || {});

    const dynamicColumns = dataKeys
      .filter(
        (key) => key !== "primVar" && key !== "uniqueKey" && key !== "children"
      ) // Exclude these keys
      .map((key) => ({
        title: key.replace(/_/g, " "), // Format key names for display
        dataIndex: key,
        key,
        onHeaderCell: () => ({
          style: {
            textAlign: "center", // Custom header alignment
          },
        }),
        render: (text) => {
          // Check if the text represents a whole number (integer)
          const isWholeOrIntegerWithZeroDecimal = /^[+-]?\d+(\.\d+)?$/.test(
            text
          );
          // Check if the text represents a decimal number or contains commas
          const isDecimal =
            /^[+-]?\d{1,3}(,\d{3})*(\.\d+)?$/.test(text) ||
            /^[+-]?\d+\.\d+$/.test(text);

          // Check if the column name contains "count"
          const isCountColumn = key.toLowerCase().includes("count");

          // Align whole numbers to the center
          if (isWholeOrIntegerWithZeroDecimal) {
            return <div style={{ textAlign: "center" }}>{text}</div>;
          }

          // Check if the column name or text contains a % symbol
          const isPercentColumn = key.toLowerCase().includes("%");

          // Align whole numbers to the center
          if (isCountColumn) {
            return <div style={{ textAlign: "center" }}>{text}</div>;
          }

          // Align percentages to the center
          if (isPercentColumn) {
            return <div style={{ textAlign: "center" }}>{text}</div>;
          }

          // Align decimal numbers, numbers with commas, percentages, or currency to the right
          if (isDecimal || text.includes("$")) {
            return <div style={{ textAlign: "right" }}>{text}</div>;
          }

          // Default alignment for other types
          return <div style={{ textAlign: "left" }}>{text}</div>;
        },
      }));

    return [...baseColumns, ...dynamicColumns];
  };

  const getViewStratsTab = async (primaryStrat) => {
    const data = {
      dealId: selectedDeals?.[0]?.value || "",
      userName: sessionStorage.getItem("user_name"),
      startDate: selectedAsOf?.label,
      prim: selectedPrimary.map((strat) => strat.label),
      sec: selectedSecondary?.value || "",
      thr: selectedTertiary?.value || "",
    };

    setLoading(true);

    try {
      const response = await fetchViewStratsTab(data);
      const result = response.data.result;

      const newTableData = {};
      const newPagination = {};
      const newExpandedRowKeys = {};

      result.forEach((resItem, index) => {
        // Parse data with unique keys
        const parsedData = resItem.results.map((item, idx) => ({
          ...item,
          uniqueKey: `row-${idx}-${uuidv4()}`,
        }));

        // Group data by dynamic flags
        const groupedData = groupDataByDynamicFlag(
          parsedData,
          selectedPrimary,
          selectedSecondary,
          selectedTertiary
        );

        // Prepare final structured data
        const finalData = Object.values(groupedData).map((primEntry) => {
          const { children: secChildren, ...restOfPrimEntry } = primEntry;

          const primData = {
            primVar: primEntry.primVar,
            uniqueKey: `row-prim-${uuidv4()}`,
            ...Object.keys(restOfPrimEntry).reduce((acc, key) => {
              if (key === "primVar" || key === "uniqueKey") {
                acc[key] = primEntry[key];
              } else {
                acc[key] = selectedSecondary?.value ? "" : restOfPrimEntry[key];
              }
              return acc;
            }, {}),
            ...(selectedSecondary?.value && {
              children: secChildren.map((secEntry) => {
                const { children: thrChildren, ...restOfSecEntry } = secEntry;

                const secData = {
                  ...restOfSecEntry,
                  uniqueKey: `row-sec-${uuidv4()}`,
                  secVar: secEntry.secVar,
                  ...Object.keys(restOfSecEntry).reduce((acc, key) => {
                    if (key === "secVar" || key === "uniqueKey") {
                      acc[key] = secEntry[key];
                    } else {
                      acc[key] = selectedTertiary?.value
                        ? ""
                        : restOfSecEntry[key];
                    }
                    return acc;
                  }, {}),
                  ...(selectedTertiary?.value && {
                    children: thrChildren.map((thrEntry) => ({
                      ...thrEntry,
                      uniqueKey: `row-thr-${uuidv4()}`, // Unique key for tertiary level
                    })),
                  }),
                };

                return secData;
              }),
            }),
          };

          return primData;
        });

        // Prepare expanded row keys for all levels
        const allKeys = finalData.reduce((keys, item) => {
          keys.push(item.uniqueKey); // Add primary key
          if (item.children) {
            item.children.forEach((secItem) => {
              keys.push(secItem.uniqueKey); // Add secondary key
              if (secItem.children && selectedTertiary?.value) {
                secItem.children.forEach((thrItem) => {
                  keys.push(thrItem.uniqueKey); // Add tertiary key
                });
              }
            });
          }
          return keys;
        }, []);

        // Store data and expanded keys
        newTableData[selectedPrimary[index].value] = finalData;
        newPagination[selectedPrimary[index].value] = { current: 1, pageSize };
        newExpandedRowKeys[selectedPrimary[index].value] = allKeys;
      });

      // Update the state
      setTableData((prev) => ({ ...prev, ...newTableData }));
      setPagination((prev) => ({ ...prev, ...newPagination }));
      setExpandedRowKeys((prev) => ({ ...prev, ...newExpandedRowKeys }));
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const groupDataByDynamicFlag = (data) => {
    return data.reduce((acc, curr) => {
      const { primVar, secVar, thrVar, ...rest } = curr;

      // Create unique keys for each level
      const primUniqueKey = `prim-${primVar}-${uuidv4()}`;
      const secUniqueKey = `sec-${secVar}-${uuidv4()}`;
      const thrUniqueKey = `thr-${thrVar}-${uuidv4()}`;

      // console.log({ primVar });

      // Initialize primary level
      if (!acc[primVar]) {
        acc[primVar] = {
          primVar,
          ...rest,
          uniqueKey: primUniqueKey,
          children: [],
        };
      }

      const primChild = acc[primVar];

      // Check if the secondary level exists and create it if not
      let secChild = primChild.children.find(
        (child) => child.secVar === secVar
      );
      if (!secChild) {
        secChild = {
          secVar,
          ...rest,
          uniqueKey: secUniqueKey,
          children: [],
        };
        // console.log({ secChild });

        primChild.children.push(secChild);
      }

      // Push the tertiary data to the secondary child
      secChild.children.push({
        thrVar,
        ...rest,
        uniqueKey: thrUniqueKey,
      });

      return acc;
    }, {});
  };

  useEffect(() => {
    if (selectedPrimary.length > 0) {
      const newTableData = {};
      selectedPrimary.forEach((primary) => {
        newTableData[primary.value] = [];
      });
      setTableData(newTableData);
      getViewStratsTab(selectedPrimary);
    }
  }, [selectedPrimary, selectedSecondary, selectedTertiary]);

  const handlePageChange = (primaryStrat, page) => {
    setPagination((prev) => ({
      ...prev,
      [primaryStrat]: { ...prev[primaryStrat], current: page },
    }));
  };

  return (
    <div className="table-container">
      {selectedPrimary && selectedPrimary.length > 0 ? (
        selectedPrimary.map((primary) => (
          <div key={primary.value}>
            <Title level={3}>{primary.label}</Title>
            <Table
              className="performance-table"
              columns={generateColumns(tableData[primary.value])}
              dataSource={tableData[primary.value] || []}
              rowKey={(record) => record.uniqueKey}
              pagination={{
                current: pagination[primary.value]?.current || 1,
                pageSize,
                total: tableData[primary.value]?.length || 0,
                onChange: (page) => handlePageChange(primary.value, page),
              }}
              bordered
              loading={loading}
              expandable={{
                expandRowByClick: true,
                childrenColumnName: "children",
                expandedRowKeys: expandedRowKeys[primary.value] || [],
                onExpand: (expanded, record) => {
                  setExpandedRowKeys((prev) => {
                    const currentKeys = prev[primary.value] || [];
                    return {
                      ...prev,
                      [primary.value]: expanded
                        ? [...currentKeys, record.uniqueKey]
                        : currentKeys.filter((key) => key !== record.uniqueKey),
                    };
                  });
                },
                rowExpandable: (record) =>
                  record.children && record.children.length > 0,
              }}
              scroll={{ x: "max-content" }}
            />
          </div>
        ))
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

export default Strats;

import React, { useState, useEffect, useRef } from "react";
import { Table } from "antd";
import Plot from "react-plotly.js";
import NumberComp2 from "../../../../../components/NumberComp2";

// const processSummaryTrancheData = (data) => {
//   if (!data || typeof data !== "object" || Array.isArray(data)) {
//     return [];
//   }

//   const keys = Object.keys(data);
//   if (!keys.includes("Tranche") || !Array.isArray(data["Tranche"])) {
//     return [];
//   }

//   const length = data["Tranche"].length;
//   if (length === 0) {
//     return [];
//   }

//   const tableData = {};

//   for (let i = 0; i < length; i++) {
//     const tranche = data["Tranche"][i];
//     const row = { key: i };

//     keys.forEach((key) => {
//       row[key] =
//         data[key] && Array.isArray(data[key]) && data[key][i] !== undefined
//           ? data[key][i]
//           : "-";
//     });

//     if (!tableData[tranche]) {
//       tableData[tranche] = [];
//     }
//     tableData[tranche].push(row);
//   }

//   const groupedData = [];

//   Object.keys(tableData).forEach((tranche) => {
//     const children = tableData[tranche];
//     groupedData.push({
//       key: tranche,
//       tranche,
//       ...children[0],
//       children: children.length > 1 ? children.slice(1) : [],
//     });
//   });

//   return groupedData;
// };

const processSummaryTrancheData = (data) => {
  if (!data || typeof data !== "object" || Array.isArray(data)) {
    return [];
  }

  const keys = Object.keys(data);
  if (!keys.includes("Tranche") || !Array.isArray(data["Tranche"])) {
    return [];
  }

  const length = data["Tranche"].length;
  if (length === 0) {
    return [];
  }

  const tableData = [];

  for (let i = 0; i < length; i++) {
    const row = { key: i };

    keys.forEach((key) => {
      row[key] =
        data[key] && Array.isArray(data[key]) && data[key][i] !== undefined
          ? data[key][i]
          : "-";
    });

    tableData.push(row);
  }

  return tableData;
};




function SummaryDash() {
  const [plotData, setPlotData] = useState({
    DA_delinquent_percentage: { data: [], layout: {} },
    DA_summary_Collateral_Performance: { data: [], layout: {} },
    DA_summary_bond_Summary: { data: [], layout: {} },
    DA_summary_paydown: { data: [], layout: {} },
  });

  const [summaryTrancheData, setSummaryTrancheData] = useState([]);
    const [tableTitles, setTableTitles] = useState([]);
    const plotContainerRef = useRef(null); // Ref to track the container

    useEffect(() => {
      const finalData = JSON.parse(sessionStorage.getItem("finalDataSummary"));

      // Check if finalData is an object and has keys
      if (finalData && Object.keys(finalData).length > 0) {
        const getTableKeys = Object.keys(finalData);
        // console.log("finalData keys:", getTableKeys);
        setTableTitles(getTableKeys);
      } else {
        console.log("finalData is empty or not found in session storage.");
      }

      if (finalData) {
        const parsePlotData = (data) => {
          let parsedData = { data: [], layout: {} };

          if (typeof data === "string") {
            parsedData = JSON.parse(data);
          } else if (typeof data === "object" && data !== null) {
            parsedData = data;
          }

          if (!parsedData.layout) {
            parsedData.layout = {};
          }

          // Set layout width to 100% using the container's width
          parsedData.layout.width =
            plotContainerRef.current?.offsetWidth || window.innerWidth;
          parsedData.layout.autosize = true;

          return parsedData;
        };

        const DA_delinquent_percentage = parsePlotData(
          finalData["Delinquencies"]
        );
        const DA_summary_Collateral_Performance = parsePlotData(
          finalData["Collateral Performance"]
        );
        const DA_summary_bond_Summary = parsePlotData(
          finalData["Bond Summary"]
        );
        const DA_summary_paydown = parsePlotData(
          finalData["Paydown (Monthly)"]
        );

        setPlotData({
          DA_delinquent_percentage,
          DA_summary_Collateral_Performance,
          DA_summary_bond_Summary,
          DA_summary_paydown,
        });

        if (finalData["Tranche Summary"]) {
          const processedData = processSummaryTrancheData(
            finalData["Tranche Summary"]
          );
          setSummaryTrancheData(processedData);
        }
      }
      // Update layout width on window resize for multiple plot data objects
      const handleResize = () => {
        setPlotData((prevPlotData) => {
          // Define the updated plot data with new widths for each layout
          const updatedPlotData = {
            ...prevPlotData,
            DA_delinquent_percentage: {
              ...prevPlotData.DA_delinquent_percentage,
              layout: {
                ...prevPlotData.DA_delinquent_percentage.layout,
                width:
                  plotContainerRef.current?.offsetWidth || window.innerWidth,
              },
            },
            DA_summary_Collateral_Performance: {
              ...prevPlotData.DA_summary_Collateral_Performance,
              layout: {
                ...prevPlotData.DA_summary_Collateral_Performance.layout,
                width:
                  plotContainerRef.current?.offsetWidth || window.innerWidth,
              },
            },
            DA_summary_bond_Summary: {
              ...prevPlotData.DA_summary_bond_Summary,
              layout: {
                ...prevPlotData.DA_summary_bond_Summary.layout,
                width:
                  plotContainerRef.current?.offsetWidth || window.innerWidth,
              },
            },
            DA_summary_paydown: {
              ...prevPlotData.DA_summary_paydown,
              layout: {
                ...prevPlotData.DA_summary_paydown.layout,
                width:
                  plotContainerRef.current?.offsetWidth || window.innerWidth,
              },
            },
          };

          return updatedPlotData;
        });
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Generate columns dynamically based on the data keys
   const generateColumns = (specialColumnData) => {
     if (summaryTrancheData.length === 0) return [];

     const keys = Object.keys(summaryTrancheData[0]).filter(
       (key) => key !== "key"
     );

     // Map each key to a column configuration
     return keys.map((key, index) => {
       let column = {
         title: key.replace(/_/g, " "), // Optionally format the title (e.g., replace underscores)
         dataIndex: key,
         key,
         // Add custom header styles for alignment
         onHeaderCell: (column) => {
           return {
             style: {
               textAlign: "center", // Adjust as needed
             },
           };
         },
         render: (text) => {
           // Check if the text represents a whole number (integer)
           const isWholeOrIntegerWithZeroDecimal = /^[+-]?\d+(\.\d+)?$/.test(
            text
          );
           // Check if the text represents a decimal number or contains commas
           const isDecimal =
             /^[+-]?\d{1,3}(,\d{3})*(\.\d+)?$/.test(text) ||
             /^[+-]?\d+\.\d+$/.test(text);

           // Check if the column name contains "count"
           const isCountColumn = key.toLowerCase().includes("count");

           // Align whole numbers to the center
           if (isWholeOrIntegerWithZeroDecimal) {
             return <div style={{ textAlign: "center" }}>{text}</div>;
           }

           // Check if the column name or text contains a % symbol
           const isPercentColumn =
             key.toLowerCase().includes("%") || text.includes("%");

           // Align whole numbers to the center
           if (isCountColumn) {
             return <div style={{ textAlign: "center" }}>{text}</div>;
           }

           // Align percentages to the center
           if (isPercentColumn) {
             return <div style={{ textAlign: "center" }}>{text}</div>;
           }

           // Align decimal numbers, numbers with commas, percentages, or currency to the right
           if (isDecimal || text.includes("$")) {
             return <div style={{ textAlign: "right" }}>{text}</div>;
           }

           // Default alignment for other types
           return <div style={{ textAlign: "left" }}>{text}</div>;
         },
       };

       // Special handling for the first column in assetLevelInfo
       if (specialColumnData && index === 0) {
         column.width = "auto"; // Set a custom width for the first column
         column.fixed = "left";
         // Add more custom properties as needed
       }

       return column;
     });
   };

   return (
     <div className="portfolioDash">
       {/* <div>
         <div className="data-display">
           <div
             className="data-item"
             style={{
               display: "flex",
               flexDirection: "column",
               justifyContent: "center",
               alignItems: "center",
             }}
           >
             <div className="data-value">Early Delinquency</div>
             <div className="data-label">
               Lorem Ipsum is simply dummy text of the printing and typesetting
               industry.
             </div>
           </div>

           <div
             className="data-item"
             style={{
               display: "flex",
               flexDirection: "column",
               justifyContent: "center",
               alignItems: "center",
             }}
           >
             <div className="data-value">Segmentation</div>
             <div className="data-label">
               Lorem Ipsum is simply dummy text of the printing and typesetting
               industry.
             </div>
           </div>
           <div
             className="data-item"
             style={{
               display: "flex",
               flexDirection: "column",
               justifyContent: "center",
               alignItems: "center",
             }}
           >
             <div className="data-value">Credit Cuts</div>
             <div className="data-label">
               Lorem Ipsum is simply dummy text of the printing and typesetting
               industry.
             </div>
           </div>
         </div>
       </div> */}
       <div className="workbench-table-container">
         <label className="tableHeaders">{tableTitles[1]}</label>
         <div className="dash-table-alignments">
           <Table
             columns={generateColumns(true)}
             className="performance-table"
             pagination={{ pageSize: 10 }}
             dataSource={summaryTrancheData}
             // bordered={false}
             scroll={{ x: "max-content" }}
           />
         </div>
       </div>
       <div className="chart-container-portfoilo">
         <div className="plot-borders" ref={plotContainerRef}>
           <Plot
             data={plotData.DA_delinquent_percentage.data}
             layout={plotData.DA_delinquent_percentage.layout}
             config={{
               // displayModeBar: true,
               displaylogo: false,
               modeBarButtonsToRemove: [
                 "pan2d",
                 "select2d",
                 "lasso2d",
                 "zoom",
                 "resetScale2d",
               ],
             }}
             className="plot-inline"
           />
         </div>
         <div className="plot-borders" ref={plotContainerRef}>
           <Plot
             data={plotData.DA_summary_bond_Summary.data}
             layout={plotData.DA_summary_bond_Summary.layout}
             config={{
               // displayModeBar: true,
               displaylogo: false,
               modeBarButtonsToRemove: [
                 "pan2d",
                 "select2d",
                 "lasso2d",
                 "resetScale2d",
                 "zoom",
               ],
             }}
             className="plot-inline"
           />
         </div>
         <div className="plot-borders" ref={plotContainerRef}>
           <Plot
             data={plotData.DA_summary_Collateral_Performance.data}
             layout={plotData.DA_summary_Collateral_Performance.layout}
             config={{
               // displayModeBar: true,
               displaylogo: false,
               modeBarButtonsToRemove: [
                 "pan2d",
                 "select2d",
                 "lasso2d",
                 "resetScale2d",
                 "zoom",
                 "zoom3d",
               ],
             }}
             className="plot-inline"
           />
         </div>
         <div className="plot-borders" ref={plotContainerRef}>
           <Plot
             data={plotData.DA_summary_paydown.data}
             layout={plotData.DA_summary_paydown.layout}
             config={{
               // displayModeBar: true,
               displaylogo: false,
               modeBarButtonsToRemove: [
                 "pan2d",
                 "select2d",
                 "lasso2d",
                 "resetScale2d",
                 "zoom",
               ],
             }}
             className="plot-inline"
           />
         </div>
       </div>
     </div>
   );
}

export default SummaryDash;

import React, { useState, useEffect, useRef } from "react";
import { Table } from "antd";
import Plot from "react-plotly.js";
import NumberComp2 from "../../../../../components/NumberComp2";

// Generic function to process data
const processData = (data, lengthKey) => {
  if (!data || Object.keys(data).length === 0) return [];
  const keys = Object.keys(data);
  const length = data[lengthKey].length;
  const tableData = [];

  for (let i = 0; i < length; i++) {
    const row = { key: i };
    keys.forEach((key) => {
      row[key] = data[key][i];
    });
    tableData.push(row);
  }

  return tableData;
};

// const columns = [
//   {
//     title: "Type",
//     dataIndex: "Type",
//     key: "Type",
//   },
//   {
//     title: "Cumulative Balance",
//     dataIndex: "Cumulative Balance",
//     key: "Cumulative Balance",
//     render: (value) => {
//       const num = parseFloat(value);
//       return isNaN(num) ? "-" : <NumberComp2 value={num} />;
//     },
//   },
//   {
//     title: "Cumulative Count",
//     dataIndex: "Cumulative Count",
//     key: "Cumulative Count",
//     // render: (value) => {
//     //   const num = parseFloat(value);
//     //   return isNaN(num) ? "-" : <NumberComp2 value={num} />;
//     // },
//   },
//   {
//     title: "Current Balance",
//     dataIndex: "Current Balance",
//     key: "Current Balance",
//     render: (value) => {
//       const num = parseFloat(value);
//       return isNaN(num) ? "-" : <NumberComp2 value={num} />;
//     },
//   },
//   {
//     title: "Current Count",
//     dataIndex: "Current Count",
//     key: "Current Count",
//     // render: (value) => {
//     //   const num = parseFloat(value);
//     //   return isNaN(num) ? "-" : <NumberComp2 value={num} />;
//     // },
//   },
// ];
// const columns1 = [
//   {
//     title: "Type",
//     dataIndex: "Type",
//     key: "Type",
//   },
//   {
//     title: "Current Count",
//     dataIndex: "Current Count",
//     key: "Current Count",
//     // render: (value) => {
//     //   const num = parseFloat(value);
//     //   return isNaN(num) ? "-" : <NumberComp2 value={num} />;
//     // },
//   },
//   {
//     title: "Initial Count",
//     dataIndex: "Initial Count",
//     key: "Initial Count",
//     // render: (value) => {
//     //   const num = parseFloat(value);
//     //   return isNaN(num) ? "-" : <NumberComp2 value={num} />;
//     // },
//   },
// ];

// const columns2 = [
//   {
//     title: "Loan Status",
//     dataIndex: "Loan Status",
//     key: "Loan Status",
//   },
//   {
//     title: "Cumulative Count",
//     dataIndex: "Cumulative Count",
//     key: "Cumulative Count",
//     // render: (value) => {
//     //   const num = parseFloat(value);
//     //   return isNaN(num) ? "-" : <NumberComp2 value={num} />; // Adjusting to show two decimal places
//     // },
//   },
//   {
//     title: "Cumulative Principal Balance",
//     dataIndex: "Cumulative Principal Balance",
//     key: "Cumulative Principal Balance",
//     render: (value) => {
//       const num = parseFloat(value);
//       return isNaN(num) ? "-" : <NumberComp2 value={num} />; // Adjusting to show two decimal places
//     },
//   },
//   {
//     title: "Current Count",
//     dataIndex: "Current Count",
//     key: "Current Count",
//     // render: (value) => {
//     //   const num = parseFloat(value);
//     //   return isNaN(num) ? "-" : <NumberComp2 value={num} />; // Adjusting to show two decimal places
//     // },
//   },
//   {
//     title: "Current Principal Balance",
//     dataIndex: "Current Principal Balance",
//     key: "Current Principal Balance",
//     render: (value) => {
//       const num = parseFloat(value);
//       return isNaN(num) ? "-" : <NumberComp2 value={num} />; // Adjusting to show two decimal places
//     },
//   },
// ];

// const columns3 = [
//   {
//     title: "Deal Name",
//     dataIndex: "Deal Name",
//     key: "Deal Name",
//     fixed: "left",
//     width: 200,
//   },
//   {
//     title: "Closing Date",
//     dataIndex: "Closing Date",
//     key: "Closing Date",
//     render: (value) => (value ? new Date(value).toLocaleDateString() : "-"), // Format as date
//   },
//   {
//     title: "First Payment Date",
//     dataIndex: "First Payment Date",
//     key: "First Payment Date",
//     render: (value) => (value ? new Date(value).toLocaleDateString() : "-"), // Format as date
//   },
//   {
//     title: "Maturity Date",
//     dataIndex: "Maturity Date",
//     key: "Maturity Date",
//     render: (value) => (value ? new Date(value).toLocaleDateString() : "-"), // Format as date
//   },
//   {
//     title: "Next Payment Date",
//     dataIndex: "Next Payment Date",
//     key: "Next Payment Date",
//     render: (value) => (value ? new Date(value).toLocaleDateString() : "-"), // Format as date
//   },
//   {
//     title: "Current Loan Count",
//     dataIndex: "Current Loan Count",
//     key: "Current Loan Count",
//     // render: (value) => {
//     //   const num = parseFloat(value);
//     //   return isNaN(num) ? "-" : <NumberComp2 value={num} />; // Show with two decimal places
//     // },
//   },
//   {
//     title: "Initial Loan Count",
//     dataIndex: "Initial Loan Count",
//     key: "Initial Loan Count",
//     // render: (value) => {
//     //   const num = parseFloat(value);
//     //   return isNaN(num) ? "-" : <NumberComp2 value={num} />; // Show with two decimal places
//     // },
//   },
//   {
//     title: "Current Principal Balance",
//     dataIndex: "Current Principal Balance",
//     key: "Current Principal Balance",
//     render: (value) => {
//       const num = parseFloat(value);
//       return isNaN(num)
//         ? "-"
//         : num.toLocaleString("en-US", { style: "currency", currency: "USD" }); // Format as currency
//     },
//   },
//   {
//     title: "Original Principal Balance",
//     dataIndex: "Original Principal Balance",
//     key: "Original Principal Balance",
//     render: (value) => {
//       const num = parseFloat(value);
//       return isNaN(num)
//         ? "-"
//         : num.toLocaleString("en-US", { style: "currency", currency: "USD" }); // Format as currency
//     },
//   },
//   {
//     title: "Non Performing",
//     dataIndex: "Non Performing",
//     key: "Non Performing",
//     render: (value) => {
//       const num = parseFloat(value);
//       return isNaN(num) ? "-" : <NumberComp2 value={num} />; // Show with two decimal places
//     },
//   },
//   {
//     title: "Performing",
//     dataIndex: "Performing",
//     key: "Performing",
//     render: (value) => {
//       const num = parseFloat(value);
//       return isNaN(num) ? "-" : <NumberComp2 value={num} />; // Show with two decimal places
//     },
//   },
//   {
//     title: "Status",
//     dataIndex: "Status",
//     key: "Status",
//   },
//   {
//     title: "WAC",
//     dataIndex: "WAC",
//     key: "WAC",
//     render: (value) => <NumberComp2 value={value} />,
//     //   {
//     //   const num = parseFloat(value);
//     //   return isNaN(num) ? "-" : <NumberComp2 value={num} />; // Show with two decimal places and percentage sign
//     // },
//   },
// ];

// const columns4 = [
//   {
//     title: "CDR/CPR",
//     dataIndex: "CDR/CPR",
//     key: "CDR/CPR",
//   },
//   {
//     title: "3-Month",
//     dataIndex: "3-Month",
//     key: "3-Month",
//     render: (value) => {
//       const num = parseFloat(value);
//       return isNaN(num) ? "-" : <NumberComp2 value={num} />; // Adjusting to show two decimal places
//     },
//   },
//   {
//     title: "Current",
//     dataIndex: "Current",
//     key: "Current",
//     render: (value) => {
//       const num = parseFloat(value);
//       return isNaN(num) ? "-" : <NumberComp2 value={num} />; // Adjusting to show two decimal places
//     },
//   },
//   {
//     title: "Life",
//     dataIndex: "Life",
//     key: "Life",
//     render: (value) => {
//       const num = parseFloat(value);
//       return isNaN(num) ? "-" : <NumberComp2 value={num} />; // Adjusting to show two decimal places
//     },
//   },
// ];
// const columns5 = [
//   {
//     title: "Deal Name",
//     dataIndex: "Deal Name",
//     key: "Deal Name",
//     fixed: "left",
//     width: 200,
//   },
//   {
//     title: "Cumulative Shortfalls",
//     dataIndex: "Cumulative Shortfalls",
//     key: "Cumulative Shortfalls",
//     render: (value) => {
//       const num = parseFloat(value);
//       return isNaN(num) ? "-" : <NumberComp2 value={num} />;
//     },
//   },
//   {
//     title: "Cumulative Writedown",
//     dataIndex: "Cumulative Writedown",
//     key: "Cumulative Writedown",
//     render: (value) => {
//       const num = parseFloat(value);
//       return isNaN(num) ? "-" : <NumberComp2 value={num} />;
//     },
//   },
//   {
//     title: "Current Liabilities Outstanding",
//     dataIndex: "Current Liabilities Outstanding",
//     key: "Current Liabilities Outstanding",
//     render: (value) => {
//       const num = parseFloat(value);
//       return isNaN(num) ? "-" : <NumberComp2 value={num} />;
//     },
//   },
//   {
//     title: "Ending Principal Balance",
//     dataIndex: "Ending Principal Balance",
//     key: "Ending Principal Balance",
//     render: (value) => {
//       const num = parseFloat(value);
//       return isNaN(num) ? "-" : <NumberComp2 value={num} />;
//     },
//   },
//   {
//     title: "# of Tranches",
//     dataIndex: "# of Tranches",
//     key: "# of Tranches",
//     render: (value) => {
//       const num = parseFloat(value);
//       return isNaN(num) ? "-" : num.toFixed(0);
//     },
//   },
//   {
//     title: "WA Cost of Funding",
//     dataIndex: "WA Cost of Funding",
//     key: "WA Cost of Funding",
//     render: (value) => <NumberComp2 value={value}/>
//     //   {
//     //   // Extract the numeric value from percentage string and format it
//     //   const num = parseFloat(value.replace("%", ""));
//     //   return isNaN(num) ? "-" : `${num.toFixed(3)}%`;
//     // },
//   },
//   {
//     title: "# of Tests",
//     dataIndex: "# of Tests",
//     key: "# of Tests",
//     render: (value) => {
//       const num = parseFloat(value);
//       return isNaN(num) ? "-" : num.toFixed(0);
//     },
//   },
//   {
//     title: "# of Fails",
//     dataIndex: "# of Fails",
//     key: "# of Fails",
//     render: (value) => {
//       const num = parseFloat(value);
//       return isNaN(num) ? "-" : num.toFixed(0);
//     },
//   },
// ];

// Utility function to generate columns dynamically

const generateColumns = (data, specialColumnData) => {
  if (!data || data.length === 0) return [];

  const keys = Object.keys(data[0]).filter((key) => key !== "key");

  return keys.map((key, index) => {
    let column = {
      title: key.replace(/_/g, " "),
      dataIndex: key,
      key,
      width: "auto",
      // Add custom header styles for alignment
      onHeaderCell: (column) => {
        return {
          style: {
            textAlign: "center", // Adjust as needed
          },
        };
      },
      render: (text) => {
        // Check if the text represents a whole number (integer)
        const isWholeOrIntegerWithZeroDecimal = /^[+-]?\d+(\.\d+)?$/.test(
          text
        );
        // Check if the text represents a decimal number or contains commas
        const isDecimal =
          /^[+-]?\d{1,3}(,\d{3})*(\.\d+)?$/.test(text) ||
          /^[+-]?\d+\.\d+$/.test(text);

        // Check if the column name contains "count"
        const isCountColumn = key.toLowerCase().includes("count");

        // Align whole numbers to the center
        if (isWholeOrIntegerWithZeroDecimal) {
          return <div style={{ textAlign: "center" }}>{text}</div>;
        }

        // Check if the column name or text contains a % symbol
        const isPercentColumn =
          key.toLowerCase().includes("%") || text.includes("%");

        // Align whole numbers to the center
        if (isCountColumn) {
          return <div style={{ textAlign: "center" }}>{text}</div>;
        }

        // Align percentages to the center
        if (isPercentColumn) {
          return <div style={{ textAlign: "center" }}>{text}</div>;
        }

        // Align decimal numbers, numbers with commas, percentages, or currency to the right
        if (isDecimal || text.includes("$")) {
          return <div style={{ textAlign: "right" }}>{text}</div>;
        }

        // Default alignment for other types
        return <div style={{ textAlign: "left" }}>{text}</div>;
      },
    };

    // Special handling for the first column in assetLevelInfo
    if (specialColumnData && index === 0) {
      column.width = "auto";
      column.fixed = "left";
    }

    return column;
  });
};

function PortfolioDash() {
  const [plotData, setPlotData] = useState({
    PA_CDR_CPR_TIME: { data: [], layout: {} },
    PA_LTV: { data: [], layout: {} },
    PA_originations_revamp: { data: [], layout: {} },
    PA_DelinquenciesGraph: { data: [], layout: {} },
    PA_performing_nonPerforming_revamp: { data: [], layout: {} },
    PA_principalBal_dealwise_revamp: { data: [], layout: {} },
    PA_revamp_state: { data: [], layout: {} },
    PA_wtAvgFico_revamp: { data: [], layout: {} },
  });

  const [collateralData, setCollateralData] = useState([]);
  const [DealAndLoanDetails, setDealAndLoanDetails] = useState([]);
  const [Delinquency, setDelinquency] = useState([]);
  const [assetLevelInfo, setassetLevelInfo] = useState([]);
  const [cdr_cpr, setcdr_cpr] = useState([]);
  const [liabilitySideInfo, setliabilitySideInfo] = useState([]);
  const [tableTitles, setTableTitles] = useState([]);
  const plotContainerRef = useRef(null); // Ref to track the container
  const scatterPlotContainer = useRef(null); // Ref to track the container

  useEffect(() => {
    // Safely parse the finalData from sessionStorage
    const finalData = JSON.parse(sessionStorage.getItem("finalData") || "{}");

    // Check if finalData is an object and has keys
    if (finalData && Object.keys(finalData).length > 0) {
      const getTableKeys = Object.keys(finalData);
      console.log("finalData keys:", getTableKeys);
      setTableTitles(getTableKeys);
    } else {
      console.log("finalData is empty or not found in session storage.");
    }

    if (finalData && Object.keys(finalData).length !== 0) {
      // Function to parse plot data with dynamic layout width
      const parsePlotData = (data) => {
        let parsedData = { data: [], layout: {} };

        if (typeof data === "string") {
          parsedData = JSON.parse(data);
        } else if (typeof data === "object" && data !== null) {
          parsedData = data;
        }

        if (!parsedData.layout) {
          parsedData.layout = {};
        }

        // Set layout width to 100% using the container's width
        parsedData.layout.width =
          plotContainerRef.current?.offsetWidth || window.innerWidth;

        return parsedData;
      };
      const parsePlotDataScatter = (data) => {
        let parsedData = { data: [], layout: {} };

        if (typeof data === "string") {
          parsedData = JSON.parse(data);
        } else if (typeof data === "object" && data !== null) {
          parsedData = data;
        }

        if (!parsedData.layout) {
          parsedData.layout = {};
        }

        // Set layout width to 100% using the container's width
        parsedData.layout.width =
          scatterPlotContainer.current?.offsetWidth || window.innerWidth;

        return parsedData;
      };

      // Parsing and setting plot data objects with the generalized function
      const PA_CDR_CPR_TIME = parsePlotData(finalData["CPR/CDR Graph"]);
      const PA_LTV = parsePlotData(finalData["Loan to Value"]);
      const PA_originations_revamp = parsePlotData(finalData["Origination"]);
      const PA_DelinquenciesGraph = parsePlotData(
        finalData["Delinquencies Graph"]
      );
      const PA_performing_nonPerforming_revamp = parsePlotData(
        finalData["Collateral Performance"]
      );
      const PA_revamp_state = parsePlotDataScatter(finalData["Property State"]);
      const PA_wtAvgFico_revamp = parsePlotData(finalData["Borrower FICO"]);
      const PA_principalBal_dealwise_revamp = parsePlotData(
        finalData["Principal Balance"]
      );

      // Set the parsed and formatted plot data into state
      setPlotData({
        PA_CDR_CPR_TIME,
        PA_LTV,
        PA_originations_revamp,
        PA_performing_nonPerforming_revamp,
        PA_DelinquenciesGraph,
        PA_revamp_state,
        PA_wtAvgFico_revamp,
        PA_principalBal_dealwise_revamp,
      });

      // Specific data processing functions calling the generic function
      const processCollateralData = (data) => processData(data, "Type");
      const processDelinquency = (data) => processData(data, "Loan Status");
      const processAssetLevelInfo = (data) => processData(data, "Deal Name");
      const processCDR_CPR = (data) => processData(data, "CDR/CPR");
      const processLiabilitySideInfo = (data) => processData(data, "Deal Name");

      // Using the functions with conditions
      if (finalData["Collection Summary"]) {
        setCollateralData(
          processCollateralData(finalData["Collection Summary"])
        );
      }
      if (finalData["Delinquency"]) {
        setDelinquency(processDelinquency(finalData["Delinquency"]));
      }
      if (finalData["Asset Summary"]) {
        setassetLevelInfo(processAssetLevelInfo(finalData["Asset Summary"]));
      }
      if (finalData["CPR/CDR"]) {
        setcdr_cpr(processCDR_CPR(finalData["CPR/CDR"]));
      }
      if (finalData["Bond Summary"]) {
        setliabilitySideInfo(
          processLiabilitySideInfo(finalData["Bond Summary"])
        );
      }
    }
    // Update layout width on window resize for multiple plots
    const handleResize = () => {
      setPlotData((prevPlotData) => {
        // Update plot data with new widths for each layout
        const updatedPlotData = {
          ...prevPlotData,
          PA_CDR_CPR_TIME: {
            ...prevPlotData.PA_CDR_CPR_TIME,
            layout: {
              ...prevPlotData.PA_CDR_CPR_TIME.layout,
              width: plotContainerRef.current?.offsetWidth || window.innerWidth,
            },
          },
          PA_LTV: {
            ...prevPlotData.PA_LTV,
            layout: {
              ...prevPlotData.PA_LTV.layout,
              width: plotContainerRef.current?.offsetWidth || window.innerWidth,
            },
          },
          PA_originations_revamp: {
            ...prevPlotData.PA_originations_revamp,
            layout: {
              ...prevPlotData.PA_originations_revamp.layout,
              width: plotContainerRef.current?.offsetWidth || window.innerWidth,
            },
          },
          PA_performing_nonPerforming_revamp: {
            ...prevPlotData.PA_performing_nonPerforming_revamp,
            layout: {
              ...prevPlotData.PA_performing_nonPerforming_revamp.layout,
              width: plotContainerRef.current?.offsetWidth || window.innerWidth,
            },
          },
          PA_DelinquenciesGraph: {
            ...prevPlotData.PA_DelinquenciesGraph,
            layout: {
              ...prevPlotData.PA_DelinquenciesGraph.layout,
              width: plotContainerRef.current?.offsetWidth || window.innerWidth,
            },
          },
          PA_principalBal_dealwise_revamp: {
            ...prevPlotData.PA_principalBal_dealwise_revamp,
            layout: {
              ...prevPlotData.PA_principalBal_dealwise_revamp.layout,
              width: plotContainerRef.current?.offsetWidth || window.innerWidth,
            },
          },
          PA_revamp_state: {
            ...prevPlotData.PA_revamp_state,
            layout: {
              ...prevPlotData.PA_revamp_state.layout,
              width:
                scatterPlotContainer.current?.offsetWidth || window.innerWidth,
            },
          },
          PA_wtAvgFico_revamp: {
            ...prevPlotData.PA_wtAvgFico_revamp,
            layout: {
              ...prevPlotData.PA_wtAvgFico_revamp.layout,
              width: plotContainerRef.current?.offsetWidth || window.innerWidth,
            },
          },
        };

        return updatedPlotData;
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="portfolioDash">
      <div className="workbench-table-container">
        <div className="dash-table-alignments">
          <label className="tableHeaders">{tableTitles[1]}</label>
          <Table
            className="performance-table"
            columns={generateColumns(assetLevelInfo, true)} // Dynamically generated columns
            dataSource={assetLevelInfo}
            pagination={{ pageSize: 10 }}
            scroll={{ x: "max-content" }}
          />
          <label className="tableHeaders">{tableTitles[2]}</label>
          <Table
            className="performance-table"
            columns={generateColumns(liabilitySideInfo, true)} // Dynamically generated columns
            dataSource={liabilitySideInfo}
            pagination={{ pageSize: 10 }}
            scroll={{ x: "max-content" }}
          />
          <label className="tableHeaders">{tableTitles[3]}</label>
          <Table
            className="performance-table"
            columns={generateColumns(collateralData, true)} // Dynamically generated columns
            dataSource={collateralData}
            pagination={false}
            // scroll={{ x: "max-content" }}
          />
          <label className="tableHeaders">{tableTitles[4]}</label>
          <Table
            className="performance-table"
            columns={generateColumns(Delinquency, true)} // Dynamically generated columns
            dataSource={Delinquency}
            pagination={false}
            // pagination={{ pageSize: 10 }}
            // scroll={{ x: "max-content" }}
          />

          {/* <label className="tableHeaders">{tableTitles[1]}</label>
          <Table
            className="performance-table"
            columns={generateColumns(DealAndLoanDetails, true)} // Dynamically generated columns
            dataSource={DealAndLoanDetails}
            pagination={{ pageSize: 10 }}
            scroll={{ x: "max-content" }}
          /> */}
        </div>
      </div>
      <div className="chart-container-portfoilo">
        <div className="plot-borders" ref={plotContainerRef}>
          <Plot
            data={plotData.PA_DelinquenciesGraph.data}
            layout={plotData.PA_DelinquenciesGraph.layout}
            config={{
              // displayModeBar: true,
              displaylogo: false,
              modeBarButtonsToRemove: [
                "pan2d",
                "select2d",
                "lasso2d",
                "resetScale2d",
                "zoom",
                // "toImage",
              ],
            }}
            className="plot-inline"
          />
        </div>
        <div className="plot-borders" ref={plotContainerRef}>
          <Plot
            data={plotData.PA_performing_nonPerforming_revamp.data}
            layout={plotData.PA_performing_nonPerforming_revamp.layout}
            config={{
              // displayModeBar: true,
              displaylogo: false,
              modeBarButtonsToRemove: [
                "pan2d",
                "select2d",
                "lasso2d",
                "resetScale2d",
                "zoom",
                // "toImage",
              ],
            }}
            className="plot-inline"
          />
        </div>
        <div className="plot-borders-cpr_cdr">
          <Table
            className="performance-table"
            columns={generateColumns(cdr_cpr, true)} // Dynamically generated columns
            dataSource={cdr_cpr}
            pagination={{ pageSize: 10 }}
            scroll={{ x: "max-content" }}
          />
        </div>
        <div className="plot-borders" ref={plotContainerRef}>
          <Plot
            data={plotData.PA_CDR_CPR_TIME.data}
            layout={plotData.PA_CDR_CPR_TIME.layout}
            config={{
              // displayModeBar: true,
              displaylogo: false,
              modeBarButtonsToRemove: [
                "pan2d",
                "select2d",
                "lasso2d",
                "zoom",
                "resetScale2d",
                // "toImage",
              ],
            }}
            className="plot-inline"
          />
        </div>

        <div className="plot-borders" ref={plotContainerRef}>
          <Plot
            data={plotData.PA_principalBal_dealwise_revamp.data}
            layout={plotData.PA_principalBal_dealwise_revamp.layout}
            config={{
              // displayModeBar: true,
              displaylogo: false,
              modeBarButtonsToRemove: [
                "pan2d",
                "select2d",
                "lasso2d",
                "resetScale2d",
                "zoom",
                // "toImage",
              ],
            }}
            className="plot-inline"
          />
        </div>

        <div className="plot-borders" ref={plotContainerRef}>
          <Plot
            data={plotData.PA_originations_revamp.data}
            layout={plotData.PA_originations_revamp.layout}
            config={{
              // displayModeBar: true,
              displaylogo: false,
              modeBarButtonsToRemove: [
                "pan2d",
                "select2d",
                "lasso2d",
                "resetScale2d",
                "zoom",
                // "toImage",
              ],
            }}
            className="plot-inline"
          />
        </div>

        <div className="plot-borders" ref={plotContainerRef}>
          <Plot
            data={plotData.PA_wtAvgFico_revamp.data}
            layout={plotData.PA_wtAvgFico_revamp.layout}
            config={{
              // displayModeBar: true,
              displaylogo: false,

              modeBarButtonsToRemove: [
                "pan2d",
                "select2d",
                "lasso2d",
                "resetScale2d",
                "zoom",
                // "toImage",
              ],
            }}
            className="plot-inline"
          />
        </div>

        <div className="plot-borders" ref={plotContainerRef}>
          <Plot
            data={plotData.PA_LTV.data}
            layout={plotData.PA_LTV.layout}
            config={{
              // displayModeBar: true,
              displaylogo: false,
              modeBarButtonsToRemove: [
                "pan2d",
                "select2d",
                "lasso2d",
                "resetScale2d",
                "zoom",
                // "toImage",
              ],
            }}
            className="plot-inline"
          />
        </div>

        <div className="plot-borders-bar-scatter" ref={scatterPlotContainer}>
          <Plot
            data={plotData.PA_revamp_state.data}
            layout={plotData.PA_revamp_state.layout}
            config={{
              // displayModeBar: true,
              displaylogo: false,
              modeBarButtonsToRemove: [
                "pan2d",
                "select2d",
                "lasso2d",
                "resetScale2d",
                "zoom",
                // "toImage",
              ],
            }}
            className="plot-inline"
          />
        </div>
      </div>
    </div>
  );
}

export default PortfolioDash;
